import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import girl from "../../images/c4-girl.webp";
import pattern from "../../images/c4-pattern.png";
import sticker1 from "../../images/c4-sticker.png";
import sticker2 from "../../images/c4-sticker2.png";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import simpleParallax from "simple-parallax-js";

class C4 extends Component {
  state = {
    ans: 0,
  };
  anim() {
    if (window !== undefined) {
      const simpleParallax = require("simple-parallax-js");
      var image = document.getElementsByClassName("sp-thumb");
      new simpleParallax(image, {
        orientation: "right",
        overflow: true,
        maxTransition: 60,
      });
    }
  }
  componentDidMount() {
    this.anim();
  }
  componentDidUpdate() {
    this.anim();
  }
  render() {
    if (!this.props.data) return <></>;
    return (
      <React.Fragment>
        <img
          alt=""
          className="c4-pattern"
          src={pattern}
          width="auto"
          height="504px"
        />
        <img
          alt=""
          className="c4-sticker2"
          src={sticker2}
          height="64px"
          width="auto"
        />
        <img
          alt=""
          className="c4-sticker1"
          src={sticker1}
          height="332px"
          width="auto"
        />
        <Container fluid id="features" className="c4 c-pad">
          <div className="c-h1 c4-h1 text-center">{this.props.data.header}</div>
          <div className="c4-h2 text-center">{this.props.data.subHeader}</div>
          <Row style={{ alignItems: "center" }}>
            <Col className="c4-col-1 col-lg-6 col-12">
              <img alt="" className="c4-girl sp-thumb" src={this.props.assets["girl_holding_junio_card"]?.url} />
            </Col>
            <Col className="c4-col-2" style={{ alignItems: "center" }}>
              <div className="d-flex flex-column justify-content-center mt-5 mt-lg-0">
                <div className="">
                  {this.props.data.content.map((item, index) => {
                    return (
                      <div className="acc-cont" key={index}>
                        <div
                          className="c8-p1 d-flex justify-content-between align-items-center"
                          onClick={() => {
                            if (this.state.ans === index) {
                              this.setState({ ans: null });
                              return;
                            }
                            this.setState({ ans: index });
                          }}
                        >
                          {item.header}
                          {this.state.ans === index ? (
                            <FontAwesomeIcon icon={faChevronUp} />
                          ) : (
                            <FontAwesomeIcon icon={faChevronDown} />
                          )}
                        </div>
                        <div
                          className="c8-d1 text-left"
                          style={{
                            display:
                              this.state.ans === index ? "block" : "none",
                          }}
                        >
                          {item.hasOwnProperty("list") && (
                            <ul>
                              {item.list.map((list_item, index) => (
                                <li key={index}>
                                  <b>{list_item.title} </b>
                                  {list_item.description}
                                </li>
                              ))}
                            </ul>
                          )}
                          {item.hasOwnProperty("paragraph") && (
                            <div
                              className="c8-d1 text-left"
                              style={{
                                display:
                                  this.state.ans === index ? "block" : "none",
                              }}
                            >
                              {item.paragraph}
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>

                {/* <div className="c4-p1">Tasks for children</div>
                <div className="c4-d1">
                  Children can earn pocket money when they complete tasks that
                  you set. Incentivise desired behaviour with bonus additions
                </div>
                <div className="c4-p1">Offers & Cashback​</div>
                <div className="c4-d1">
                  <ul>
                    <li>
                      Get upto 7% cashback on any transactions. Be it online or
                      offline, Amazon, Swiggy, Zomato, Paytm or any shop near
                      you.
                    </li>

                    <li>
                      50% off up to INR 100 on Junio card on 2 trips. No minimum
                      transaction amount is required. Offer valid on Junio Rupay
                      Card. Offer is valid till 31st Dec’21. Offer valid in all
                      cities where Uber services are present.
                      <br /> Coupon Code - UBERUPAYC
                    </li>
                  </ul>
                </div>
                <div className="c4-p1">Refer & Earn​</div>
                <div className="c4-d1 mb-0">
                  You can earn up to Rs.1000 in referral rewards when someone
                  orders a Junio Card by using your referral code.
                </div>
               */}
              </div>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default C4;
